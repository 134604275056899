<template>
    <div>
        <div v-bind:class="{ faded: !isShippingAddressComplete }">
            <h3>Shipping Method</h3>

            <div class="shipping-method-container row mb-">
                <div class="col-md-6 col-lg-4">
                    <div class="shipping-method d-flex align-items-center">
                        <div class="mr-4">
                            <input v-model="shipping.method" type="radio" value="pickup" id="pickup" class="mr-1" :disabled="!isShippingAddressComplete" />
                            <label for="pickup">Pickup</label>
                        </div>

                        <div class="mr-4">
                            <input v-model="shipping.method" type="radio" value="delivery" id="delivery" class="mr-1" :disabled="!isShippingAddressComplete" />
                            <label for="delivery">Delivery</label>
                        </div>
                    </div>

                    <div v-if="shipping.method === 'pickup'" class="pickup-location">
                        <div class="d-flex mb-2">
                            <span>At: </span>

                            <select v-model="shipping.pickupLocation" class="form-control form-control-sm form-location ml-3" name="pickupLocation"  v-if="pickupLocations.length > 0" :disabled="!isShippingAddressComplete">
                                <option :value="produceExpress">{{ produceExpress.name }} {{ produceExpress.city }}</option>
                                <option :value="location" v-for="location in pickupLocations" :key="location.id">{{ location.name }}</option>
                            </select>
                        </div>

                        <div v-if="shipping.method === 'pickup'" class="pickup_address">
                            <p class="mb-0">{{ shipping.pickupLocation.street_1 }} {{ shipping.pickupLocation.city }}</p>
                            <p class="mb-0" v-if="pickupDaysOfWeek" style="font-size: 16px; color: #458700; line-height:1.375;">Pickup Available: <br> <span v-text="pickupDaysOfWeek"></span> <span v-text="pickupHours"></span></p>
                        </div>

                    </div>

                    <div v-if="shipping.method === 'delivery'">
                        <p class="mb-1" style="font-size: 14px;" v-if="deliveryFee !== false">
                            Delivery ${{ this.deliveryFee }}.
                            <span v-if="deliveryFreeDeliveryAvailable">Free delivery for orders over $75</span>
                            <span v-else>Free delivery over $75 <strong>not available</strong> in this area</span>
                        </p>

                        <p class="mb-0" style="font-size: 16px; line-height:1.375;" v-if="deliveryMessage" v-text="deliveryMessage"></p>
                    </div>
                </div>

                <div class="col-md-6 col-lg-8">
                    <div class="d-flex align-items-end" style="height: 100%;">
                        <div v-if="!pickupLoading && !deliveryLoading && !deliveryMessage && (shipping.date === '' || shipping.time === '') && ((shipping.method === 'pickup' && isShippingAddressComplete) || (shipping.method === 'delivery' && !isShippingPostalInvalid && isShippingAddressComplete))" class="hint">
                            Please select a time and date before continuing
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="shipping.method === 'pickup'">
                <div class="available-dates pickup mt-3">
                    <div v-if="pickupLoading" style="font-size: 2rem; text-align: center;">
                        <img src="/images/icons/spinner.svg" width="36px" height="36px" class="spin" alt="Loading..." />
                    </div>

                    <div v-else-if="isShippingAddressComplete">
                        <div class="card"
                            v-for="(item, index) in pickupDates"
                            :key="index"
                            :class="{ selected: shipping.date == index, 'cursor-not-allowed': !isShippingAddressComplete }"
                            v-on:click.self="setShippingDate(index, item)"
                        >
                            <h4 class="mb-2 d-inline-block" v-html="item.title" v-on:click.self="setShippingDate(index, item)"></h4>

                            <div v-if="item.status === 'available'">
                                <div class="time" :class="{ 'text-left' : item.slots.length > 1 }" v-show="shipping.date == index">
                                    <div v-for="(slot, index) in item.slots" :key="index" class="custom-control custom-radio custom-control-inline mt-1">
                                        <div v-if="item.slots.length > 1">
                                            <input
                                                v-model="shipping.time"
                                                :id="item.title + '_' + slot.id"
                                                :name="item.title + '_' + slot.id"
                                                :value="slot"
                                                @click="handleScrollToBottom"
                                                type="radio"
                                                class="custom-control-input"
                                            />
                                            <label class="custom-control-label" :for="item.title + '_' + slot.id" v-text="slot.label"></label>
                                        </div>

                                        <span v-text="slot.label" v-else></span>
                                    </div>
                                </div>
                            </div>

                            <div v-else>Upcoming*</div>
                        </div>
                    </div>
                    <div v-if="pickupHasUpcomingDates">
                        <small class="text-muted" style="line-height: 1.2">
                            * Orders must be placed within 2 business days of pickup <span v-if="!pickupHasAvailableDates">, please come back later.</span>
                        </small>
                    </div>
                    <div v-if="pickupUnavailable">No available dates, please check back later.</div>
                </div>
            </div>

            <!-- Delivery -->
            <div v-show="shipping.method === 'delivery'">
                <div class="available-dates mt-3">
                    <div v-if="deliveryLoading" style="font-size: 2rem; text-align: center;">
                        <img src="/images/icons/spinner.svg" width="36px" height="36px" class="spin" alt="Loading..." />
                    </div>

                    <div v-else >
                        <div class="delivery-wrap d-flex">
                            <div v-for="(day, date) in deliveryDates" :key="date">
                                <div class="card-delivery"
                                    :class="{ selected: shipping.date == date }"
                                    v-on:click="setShippingDate(date, day)"
                                >
                                    <h4
                                        class="d-inline-block mb-2"
                                        @click.self="setShippingDate(date, day)"
                                    >
                                        <span style="font-weight: 600; font-size: 20px;">{{ day.title }}</span>
                                        <small style="font-size: 70%;">{{ day.formatted }}</small>
                                    </h4>
                                </div>

                                <div v-if="day.status === 'available'">
                                    <div class="delivery-time" :class="{ 'text-left' : day.slots.length > 1 }">
                                        <div v-for="(slot, index) in day.slots" :key="index" class="custom-control custom-radio custom-control-inline mt-1">
                                            <div v-if="day.slots.length > 0">
                                                <input
                                                    v-model="time"
                                                    :id="day.title + '_' + slot.id"
                                                    :name="day.title + '_' + slot.id"
                                                    :value="day.title + '_' + slot.id"
                                                    @click="handleDeliveryTime(date, slot)"
                                                    type="radio"
                                                    class="custom-control-input"
                                                />
                                                <label class="custom-control-label" :for="day.title + '_' + slot.id" v-text="getSlotLabel(slot)"></label>
                                            </div>

                                            <span v-text="slot.label" v-else></span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p v-if="day.status === 'soldout'" class="delivery-time unavailable">Sold Out</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="deliveryDates.length === 0">
                            <p>
                                <small class="text-muted">
                                    * Orders must be placed within {{ businessDays }} business days of delivery <span v-if="!deliveryHasAvailableDates">, please come back later.</span>
                                </small>
                            </p>
                        </div>

                        <label>Delivery Instructions (Optional)</label>
                        <textarea-autosize class="form-control mb-5" v-model="shipping.notes" maxlength="255" :min-height="34">
                        </textarea-autosize>
                    </div>
                </div>
            </div>
            <!-- / Delivery -->
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { mapGetters } from 'vuex'
    import { trackAddShippingInfo } from "../dataLayer.js";

    export default {
        props: [
            'shipping',
            'availableDates',
            'availableProduceExpressDates',
            'shippingTrigger',
            'isShippingAddressComplete',
            'isShippingPostalInvalid',
            'needConfirmAddress',
        ],

        data () {
            return {
                time: null,
                shippingMethodMessage: '',

                pickupHours: '',
                pickupDaysOfWeek: '',
                pickupDates: [],
                pickupLoading: false,
                pickupHasUpcomingDates: false,
                pickupHasAvailableDates: false,
                pickupUnavailable: false,

                deliveryDates: [],
                deliveryFee: false,
                deliveryFreeDeliveryAvailable: false,
                deliveryMessage: false,
                deliveryHasAvailableDates: false,
                deliveryLoading: false,

                produceExpress: {
                    id: 0,
                    name: 'Produce Express',
                    city: 'Woodstock',
                    street_1: '454 Innovation Way',
                },

                pickupLocations: [],
                invalidProducts: [],
                showInvalidModal: false,
                businessDays: 2,
            }
        },

        computed: {
            ...mapGetters([
                'productsInCart',
                'grandTotal',
            ]),
        },

        methods: {
            setShippingDate (date, data) {
                if (data.status !== 'available' || !this.isShippingAddressComplete) {
                    this.shipping.date = date;
                    this.shipping.time = '';
                    this.time = null;

                    return;
                }

                this.shipping.date = date;
                this.shipping.dateLabel = data.title;

                if (data.slots.length === 1) {
                    this.time = data.title + '_' + data.slots[0].id;
                    this.shipping.time = data.slots[0];
                } else {
                    this.shipping.time = "";
                }
            },

            resetDelivery() {
                this.deliveryDates = [];
                this.deliveryFee = false;
                this.deliveryHasAvailableDates = false;
                this.deliveryMessage = '';
                this.shipping.date = '';
                this.shipping.time = '';
                this.time = null;
            },

            handleScrollToBottom () {
                setTimeout(function() {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 100);
            },

            getSlotLabel(slot) {
                return slot.label ? slot.label : `${slot.from} - ${slot.to}`;
            },

            handleDeliveryTime(date, slot) {
                this.shipping.date = date;
                this.shipping.time = slot;

                this.handleScrollToBottom();
            },

            fetchDeliveryDates: _.debounce(function() {
                this.deliveryLoading = true;

                this.axios
                    .get(process.env.VUE_APP_API_WHOLESALE + '/api/find-delivery-dates', {
                        params: {
                            name: this.shipping.first_name + ' ' + this.shipping.last_name,
                            email: this.shipping.email,
                            phone: this.shipping.phone,
                            address: this.shipping.address_1,
                            city: this.shipping.city,
                            province: this.shipping.province,
                            postal: this.shipping.postal.replace(' ', ''),
                            lat: this.shipping.lat,
                            lng: this.shipping.lng,
                        }
                    })
                    .then(response => {
                        if (response.data.result === 'success') {
                            this.shipping.lat = response.data.lat;
                            this.shipping.lng = response.data.lng;
                            this.shipping.deliveryZone = response.data.zone_id;
                            this.deliveryDates = response.data.dates;
                            this.deliveryFee = parseFloat(response.data.fee);
                            this.deliveryFreeDeliveryAvailable = response.data.free_delivery_available;
                            this.businessDays = response.data.business_days;

                            if (this.deliveryDates.length === 0) {
                                this.deliveryMessage = 'No available dates, please check back later.';
                            } else {
                                for (let date in this.deliveryDates) {
                                    // preselect
                                    if (!this.shipping.date) {
                                        this.setShippingDate(date, this.deliveryDates[date]);
                                    }

                                    if (this.deliveryDates[date].status === 'available') this.deliveryHasAvailableDates = true;
                                }

                                this.deliveryMessage = '';
                            }
                        }
                        else if (response.data.result === 'invalid_postal') {
                            this.deliveryMessage = 'Delivery is currently not available in your area.';
                        }
                        else if (response.data.result === 'invalid_address') {
                            this.deliveryMessage = response.data.reason;
                        }
                        else {
                            this.deliveryMessage = 'No available dates, please check back later.';
                        }

                        this.$store.commit('setShipping', {
                            method: this.shipping.method,
                            fee: this.deliveryFee,
                            freeAvailable: this.deliveryFreeDeliveryAvailable,
                        });
                    }).finally(() => {
                        this.deliveryLoading = false;
                    });
            }, 250),
        },

        mounted () {
            this.$watch((vm) => (vm.shipping.method, vm.shipping.pickupLocation, Date.now()), () => {
                this.shipping.date = "";
                this.shipping.time = "";
                this.pickupHasUpcomingDates = false;
                this.pickupHasAvailableDates = false;
                this.pickupUnavailable = false;

                if (this.shipping.method !== 'pickup') {
                    return;
                }

                if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                    this.pickupDates = this.availableProduceExpressDates;
                    this.pickupDaysOfWeek = '';
                    this.pickupHours = '';
                } else {
                    this.pickupDates = [];
                    this.pickupLoading = true;

                    var data = {
                        params: { pickup_location: this.shipping.pickupLocation.id }
                    };

                    this.axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/find-dates', data).then(response => {
                        this.pickupLoading = false;
                        this.pickupDates = response.data.dates;
                        this.pickupDaysOfWeek = response.data.daysOfWeek;
                        this.pickupHours = response.data.hours;

                        if (this.pickupDates.length === 0) {
                            this.pickupUnavailable = true;
                        } else {
                            for (let date in this.pickupDates) {
                                if (this.pickupDates[date].status === 'upcoming') this.pickupHasUpcomingDates = true;
                                if (this.pickupDates[date].status === 'available') this.pickupHasAvailableDates = true;
                            }
                        }

                        // Autoselect date/time if there's only one applicable
                        var dates = Object.keys(this.pickupDates);

                        if ((dates.length === 1 && !this.pickupHasUpcomingDates) || (dates.length == 2 && this.pickupHasAvailableDates)) {
                            this.shipping.date = dates[0];
                            this.shipping.time = this.pickupDates[dates[0]].slots[0];
                            this.handleScrollToBottom();
                        }
                    })
                    .catch(() => {
                        this.pickupLoading = false;
                    });
                }
            });

            this.$watch((vm) => (vm.shipping.address_1, vm.shipping.city, vm.shipping.province, Date.now()), () => {
                if (this.isShippingAddressComplete && this.shipping.method === 'delivery') {
                    this.fetchDeliveryDates();
                } else {
                    this.resetDelivery();
                }
            });

            this.$watch((vm) => (vm.shipping.method, vm.shipping.address_1, vm.shipping.city, vm.shipping.province, vm.shipping.postal, Date.now()), () => {
                if (this.isShippingAddressComplete && !this.isShippingPostalInvalid && this.shipping.method !== '') {
                    trackAddShippingInfo(this.productsInCart, this.grandTotal, this.shipping.method);
                }
            });

            this.axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/pickup-locations').then(response => {
                this.pickupLocations = response.data;
            });

            this.shipping.pickupLocation = this.produceExpress;
        },

        watch: {
            'shipping.postal' () {
                if (!this.isShippingPostalInvalid && this.shipping.method == 'delivery') {
                    this.fetchDeliveryDates();
                } else {
                    this.resetDelivery();
                }
            },

            'shipping.method' () {
                this.shipping.date = '';
                this.shipping.time = '';
                this.time = null;

                if (this.shipping.method === 'delivery') {
                    this.shipping.pickupLocation = this.produceExpress;
                    this.fetchDeliveryDates();
                }

                this.$store.commit('setShippingMethod', this.shipping.method);
            },

            'shipping.pickupLocation' (newValue) {
                this.$store.commit('setPickupLocation', newValue.id);
            },

            pickupLocation () {
                this.shipping.date = '';
            },

            pickupDates (newValue) {
                if (newValue === null || newValue.length === 0) {
                    this.pickupUnavailable = true;
                } else {
                    this.pickupUnavailable = false;
                }
            },

            availableProduceExpressDates (newValue) {
                if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                    this.pickupDates = newValue;
                    this.shipping.date = '';
                    this.shipping.time = '';
                }
            },

            availableDates (newValue) {
                this.pickupDates = newValue;
                this.shipping.date = '';
                this.shipping.time = '';
            },

            shippingTrigger (newValue) {
                if (newValue === 'bad_address') {
                    this.resetDelivery();
                    this.deliveryMessage = 'Delivery is currently not available in your area.';
                } else if (newValue === 'bad_delivery_slot') {
                    this.resetDelivery();
                    this.fetchDeliveryDates();
                }
            },
        },
    }
</script>

<style scoped>
h3 {
    margin: 1rem 0;
}

.pickup-location {
    flex: 1;
}

@media screen and (min-width: 640px) {
    .pickup-location {
        flex: unset;
    }
}

.available-dates.pickup {
    max-width: 600px;
}

.available-dates p {
    line-height: 1.2;
}

.form-location {
    max-width: 240px;
}

.available-dates .card {
    display: inline-block;
    padding: 12px;
    width: 100%;
    margin-bottom: 6px;
    text-align: center;
    vertical-align: top;
    min-height: 102px;
    border: 2px solid #e0dede;
}

.available-dates .card:hover {
    cursor: pointer;
    border: 1px solid #85C71E;
    background-color: #edfff5;
    box-sizing: border-box;
}

.available-dates .card-delivery {
    display: inline-block;
    padding: 3px;
    width: 100%;
    margin-bottom: 6px;
    text-align: center;
    vertical-align: top;
    min-height: 65px;
    border-radius: 10px;
    border: 2px solid #e0dede;
}

.available-dates .card-delivery:hover,
.available-dates .card-delivery.selected {
    cursor: pointer;
    border: 1px solid #85C71E;
    background-color: #85C71E;
    color: #fff;
    box-sizing: border-box;
}

.available-dates .card-delivery {
    width: 175px;
    margin-right: 8px;
}

.available-dates div:last-child > .card-delivery{
    margin-right: 0 !important;
}

.available-dates .card.cursor-not-allowed,
.available-dates .card-delivery.cursor-not-allowed {
    cursor: not-allowed;
}

.available-dates .card.selected,
.available-dates .card-delivery.selected {
    border: 2px solid #85C71E;
}

.available-dates .card.selected:hover,
.available-dates .card-delivery.selected:hover {
    cursor: default;
}

.available-dates .card h4,
.available-dates .card-delivery h4 {
    font-size: 18px;
    margin-top: 0;
}

.available-dates .card h4 span,
.available-dates .card-delivery h4 span {
    display: block;
    font-size: 14px;
}

.available-dates .custom-control {
    padding-left: 0;
}

.available-dates .custom-control-label {
    padding-left: 2rem;
    text-transform: capitalize;
    line-height: 1.5rem;
}

.available-dates .custom-control-label::after,
.available-dates .custom-control-label::before {
    left: 0.75rem;
}

.available-dates .custom-control-label:hover {
    cursor: pointer;
}

.available-dates .time {
    font-size: 14px;
}

.available-dates .delivery-time {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.available-dates .delivery-time.unavailable {
    font-size: 19px;
}

.delivery-wrap {
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (min-width: 700px) {
    .available-dates .card {
        width: calc(50% - 6px);
        margin-right: 8px;
    }

    .available-dates .card:last-child {
        width: calc(50% - 2px);
        margin-right: 0;
    }
}

@media screen and (max-width: 499px) {
    .delivery-wrap {
        flex-direction: column;
        align-items: center;
    }
}

.shipping-method-container {
    position: relative;
}

.shipping-method-container .hint {
    position: relative;
    background: #85C71E;
    border-radius: 6px;
    padding: 1rem 0.5rem;
    color: #fff;
    max-width: 240px;
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
    margin: 1.5rem auto 1.5rem;
}

.shipping-method-container .hint:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 120px;
    margin-left: -20px;
    width: 0;
    height: 0;
    border-top: solid 20px #85C71E;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
}

@media (min-width: 768px) {
    .shipping-method-container .hint {
        margin-left: 0;
        margin-right: 0;
    }
}

.pickup_address p {
    font-size: 16px;
}
</style>
